import React from "react";
import SEO from "../components/seo/seo";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import SideVerticalText from "../components/layout/sideVerticalText";
import BottomFactory from "../components/layout/BottomFactory";
import FadeIn from "../components/animations/FadeIn";

export const query = graphql`query ($slug: String!) {
  allTransportersJson(slug: {eq: $slug}) {
    title
    subTitle
    characteristics
    about
    table {
      tableL
      tableR
    }
    compatiblePl
    compatibleWithProcessLines {
      name
      linkTo
      compatibleWithPLImage {
        childImageSharp {
          gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
    image01 {
      childImageSharp {
        original {
          src
        }
      }
    }
    image02 {
      childImageSharp {
        original {
          src
        }
      }
    }
    image03 {
      childImageSharp {
        original {
          src
        }
      }
    }
  }
}
`;

const AllTransporter = ({ data, intl }) => {
	const transporter = data.allTransportersJson;

	const graphImage1 = transporter.image01.childImageSharp.original.src;
	const graphImage2 = transporter.image02.childImageSharp.original.src;
	const graphImage3 = transporter.image03.childImageSharp.original.src;
	const images = [
		{
			original: graphImage1,
			thumbnail: graphImage1,
			originalAlt: 'machine Image'
		},
		{
			original: graphImage2,
			thumbnail: graphImage2,
			originalAlt: 'machine Image'
		},
		{
			original: graphImage3,
			thumbnail: graphImage3,
			originalAlt: 'machine Image'
		},
	];

	return <>
        <SEO
            title={intl.formatMessage({ id: "machineSelection.transporters" })}
        />

        {/* ----------------------------------------------------    H O M E     ------------------------------------------------ */}

        <div className="gridAllMachinesCreatePagesHome">
            <div className="boxAllMachinesCreatePagesHomeSideText">
                <FadeIn duration="2s" delay="0.5s"><SideVerticalText /></FadeIn>
            </div>
            <div className="boxAllMachinesCreatePagesHomeTopText">
                <div className="allMachinesSecondLevelTitle">
                    <p>
                        <FormattedMessage id={transporter.title} />
                    </p>
                </div>
                <div className="allMachinesSecondLevelSubTitle">
                    <div className="allAllMachinesTransportersCircle"></div>
                    <p className="allAllMachinesCircleText">
                        <FormattedMessage id={transporter.subTitle} />
                    </p>
                </div>
            </div>
            <div className="boxAllMachinesCreatePagesHomeLeft">
                <ImageGallery
                    items={images}
                    lazyLoad={false}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    useBrowserFullscreen={false}
                    showThumbnails={false}
                    showNav={true}
                    showBullets={false}
                    autoPlay={false}
                    slideDuration={1000}
                    /*slideInterval={10000} */
                />
            </div>

            <div className="boxAllMachinesCreatePagesHomeRight">
                <div className="allAllMachinesTableWrapper">
                    <p className="allAllMachinesCharacteristics">
                        <FormattedMessage id={transporter.characteristics} />
                    </p>
                    <table className="table">
                        <tbody>
                            {transporter.table.map((item, index) => {
                                const { tableL, tableR } = item;
                                return (
                                    <tr key={index} className="allAllMachinesTable">
                                        <td className="allAllMachinesTableTextLeft">
                                            <FormattedMessage id={tableL} />
                                        </td>
                                        <td className="allAllMachinesTableTextRight">
                                            <FormattedMessage id={tableR} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="boxAllMachinesCreatePagesHomeEmptyRight"></div>
            <div className="boxAllMachinesCreatePagesHomeBottomText">
                <BottomFactory />
            </div>
        </div>

        <div className="allAllMachinesSection02">
            {/* ----------------------------------------------------    A B O U T  Section     ------------------------------------------------ */}
            <div className="allAllMachinesAboutBox">
                <div className="allAllMachinesMainTextWrapper">
                    <FormattedMessage id={transporter.about} />
                </div>
            </div>

            {/* ----------------------------------------------------    MachinesPLCompatible Section     ------------------------------------------------ */}

            <div className="allMachinesFilterSection">
                {transporter.compatibleWithProcessLines.some(() => {
                    const isTrue = [];
                    if (isTrue) {
                        return true;
                    }
                    return null;
                }) ? (
                    <div className="filterSectionInside">
                        <div className="filterSectionTitle">
                        <div className="allAllMachinesProcessCircle"></div>
                            <p>
                                <FormattedMessage id={transporter.compatiblePl} />
                            </p>
                        </div>
                        <div className="filterSectionMap">
                            {transporter.compatibleWithProcessLines.map((item, index) => {
                                const { name, linkTo } = item;
                                return (
                                    <div
                                        key={index}
                                        className="filterSectionCardProcess"
                                    >
                                        <Link to={linkTo}>
                                            <GatsbyImage
                                                image={item.compatibleWithPLImage.childImageSharp.gatsbyImageData}
                                                className="filterSectionCardProcessImage"
                                                alt="filterSectionCardImage"
                                                loading="eager" />
                                            <span className="filterSectionCardText">
                                                <FormattedMessage id={name} />
                                            </span>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    " "
                )}
            </div>
        </div>
    </>;
};

export default injectIntl(AllTransporter);
